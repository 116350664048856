
/**
 * 根據api的response
 * permissions 裡的權限，判斷組件內的UI是否呈現
 */
import { defineComponent, ref, toRefs } from 'vue';
import { getPermissions } from '@/utils/local-storage';

// export check permission logic
export const canIDo = (whatYouWhatToDo: string) => {
  const permissions = getPermissions();
  return permissions.some(({ name }) => name === whatYouWhatToDo);
};

export default defineComponent({
  props: {
    can: {
      type: String,
      required: true
    }
  },
  emits: ['isPermission'],
  setup(props, { emit }) {
    const { can } = toRefs(props);
    const accessible = ref(false);

    accessible.value = canIDo(can.value);

    // TODO: I think event name need to be rename
    emit('isPermission', accessible.value);

    return {
      accessible
    };
  }
});
